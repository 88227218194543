import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { enableAbout as enabled, author } from "../../../customize"

const AboutLink = () => {
  return (
    <>
      {enabled ? (
        <MainCardNameAboutEnabled>
          <h2>
            <Link to="/about">{author}</Link>
          </h2>
        </MainCardNameAboutEnabled>
      ) : (
        <MainCardName>
          <h2>{author}</h2>
        </MainCardName>
      )}
    </>
  )
}

export default AboutLink

const MainCardName = styled.div.attrs(_ => ({
  className: "main-card-name"
}))`
  display: inline-block;
  cursor: default;
  h2 {
    padding: 0.2rem 0.4rem;
  }
`

const MainCardNameAboutEnabled = styled(MainCardName)`
  cursor: pointer;
`
